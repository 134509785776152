import React, { FC } from 'react';
import Text, { TextType } from '../../../../../../../../Components/Text';
import { BookingDTO } from '../../../../../../../../../../types';
import { useFormatters } from '../../../../../../../../../../core/hooks/useFormatters';
import { BookingBodyContentDurationDataHooks } from '../../../../../../../datahooks';
import { isCourse } from '../../../../../../../../../../utils/bookInfo';

export interface BookingBodyContentDurationProps {
  booking: BookingDTO;
}

const BookingBodyContentDuration: FC<BookingBodyContentDurationProps> = ({
  booking,
}) => {
  const { getDuration } = useFormatters();

  if (isCourse(booking)) {
    return null;
  }

  return (
    <div data-hook={BookingBodyContentDurationDataHooks.Main}>
      <Text
        data-hook={BookingBodyContentDurationDataHooks.Text}
        type={TextType.Secondary}
      >
        {getDuration(new Date(booking.start), new Date(booking.end))}
      </Text>
    </div>
  );
};

export default BookingBodyContentDuration;
